<template>
    <div class="bar-loader">
        <div class="fill"></div>
    </div>
    
    <Menu ref="Menu" class="menu" v-if="WAT && this.$route.meta.Title !== 'Narrowcasting'" />
    <Bar ref="Bar" class="bar" v-if="WAT && this.$route.meta.Title !== 'Narrowcasting'" />
    <Media ref="Media" class="media" v-if="WAT && Media && this.$route.meta.Title !== 'Narrowcasting'" :Focus="MediaFocus" :Type="MediaType" />

    <Page ref="Page" class="page" v-if="WAT && Page && this.$route.meta.Title !== 'Narrowcasting'" :PageIndex="PageIndex" />
    <CreatePage ref="CreatePage" class="page" v-if="WAT && CreatePage && this.$route.meta.Title !== 'Narrowcasting'" />

    <router-view class="router" ref="router" v-if="this.$route.meta.Title !== 'Narrowcasting'" />

    <Narrowcasting class="narrowcasting" ref="narrowcasting" v-if="this.$route.meta.Title == 'Narrowcasting'" />
</template>

<script>
import Pusher from 'pusher-js'

import { v4 as uuidv4 } from 'uuid';

import Menu from '@/views/Layout/Menu.vue';
import Bar from '@/views/Layout/Bar.vue';
import Media from '@/views/Layout/Media.vue';
import Page from '@/views/Layout/Page.vue';
import CreatePage from '@/views/Layout/CreatePage.vue';
import Narrowcasting from '@/views/Narrowcasting/Screen.vue'

export default {
    name: 'App',

    components: {
        Menu,
        Bar,
        Media,
        Page,
        CreatePage,
        Narrowcasting
    },

    mounted () {
        this.Session()
    },

    data () {
        return {
            WAT: localStorage.getItem('WAT'),
            Media: false,
            MediaType: false,
            MediaFocus: false,
            Page: false,
            PageIndex: false,
            CreatePage: false,
            PusherStream: false,

            Client: false,
            Placeholder: false
        }
    },
    
    methods: {
        async Session () {
            await this.$http.get('https://api.wheels.nl/auth/session').then((response) => {
                if(response.data.Client) { this.Client = response.data.Client }
                // if(response.data.settings.Placeholder) { this.Placeholder = response.data.settings.Placeholder }

                // if(response.data.client) {
                //     this.SetPusher(response.data.client)
                // }

                // if(response.data.settings.Favicon) {
                //     var link = document.querySelector("link[rel~='icon']")

                //     if (!link) {
                //         link = document.createElement('link')
                //         link.rel = 'icon'
                //         document.head.appendChild(link)
                //     }

                //     link.href = 'https://api.wheels.nl/vault/' + response.data.settings.Client + '/' + response.data.settings.Favicon + '.ico'
                // }
            })
        },

        SetPusher (e) {
            this.PusherStream = new Pusher('cab193158f187399fe70', {
                cluster: 'eu'
            })

            var self = this
            self.PusherStream.subscribe(e)
            self.PusherStream.bind('inbox-new-message', function(data) {
                if(self.$route.meta.Title !== 'Leads' && data.Content) {
                    self.$toast.clear()
                    self.$toast.open({
                        message: data.Content,
                        position: 'top-right',
                        type: 'chat',
                        duration: 15000
                    })
                }
            })
        },

        OpenMedia (e, a) {
            var FocusID = uuidv4()
            e.Active = FocusID
            this.MediaFocus = FocusID

            this.Media = true
            this.MediaType = a
        },

        CloseMedia () {
            this.Media = false
        },

        OpenPage (i) {
            this.Page = true
            this.PageIndex = i
        },

        ClosePage () {
            this.Page = false
            this.PageIndex = false
        },

        OpenCreatePage () {
            this.CreatePage = true
        },

        CloseCreatePage () {
            this.CreatePage = false
            this.PageIndex = false
        },

        async Logout () {
            await this.$http.post('https://api.wheels.nl/auth/logout').then(() => {
                localStorage.clear()
                location.href = '/login'
            })
        }
    }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Baloo+2:wght@400..800&display=swap');

* {
    font-family: "Baloo 2", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-size: 16px;
    font-style: normal;
    margin: 0;
    padding: 0;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    color: #333;
    box-sizing: border-box;
}

html, body, #app {
    width: 100%;
    height: 100%;
    background: #eaeaea;
}

#app {
    display: grid;
    grid-template-columns: 250px calc(100% - 250px);
    grid-template-rows: 100px calc(100% - 100px);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    background: #fff;
}

#app.narrowcasting {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.menu {
    grid-area: 1 / 1 / 3 / 2;
}

.bar {
    grid-area: 1 / 2 / 2 / 3;
}

.router {
    grid-area: 2 / 2 / 3 / 3;
}

.bar-loader {
    position: fixed;
    top: -5px;
    left: 0;
    width: 100%;
    height: 5px;
    background: #eeeeee;
    transition: .25s;
}

.bar-loader.active {
    top: 0;
}

.bar-loader .fill {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 5px;
    background: #0071d7;
    transition: .25s;
}

.bar-loader .fill.third {
    width: 33%;
}

.bar-loader .fill.full {
    width: 100%;
}

.v-toast__item--chat {
    background: #fff;
    max-width: 400px;
}

.v-toast__item--chat .v-toast__text {
    padding: 25px !important;
}

.v-toast__item--chat .v-toast__text .title {
    font-weight: 600 !important;
}

.v-toast__item--chat .v-toast__icon {
    display: none !important;
}
</style>