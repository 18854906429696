<template>
    <div class="clients">
        <div class="list">
            <span class="title">Klanten</span>
            <div class="clients-list">
                <div class="list-item" v-for="(Client, i) in Clients" :key="i">
                    <div class="type">
                        <span class="pill person" v-if="Client.customer_type_name == 'Klant'">Persoon</span>
                        <span class="pill business" v-if="Client.customer_type_name == 'Zakelijk'">Bedrijf</span>
                        <span class="pill other" v-if="Client.customer_type_name !== 'Klant' && Client.customer_type_name !== 'Zakelijk'">Overig</span>
                    </div>
                    <router-link :to="'/klanten/' + Client.customer_id" class="name">{{ Client.phone_numbers[0].name }}</router-link>
                    <span class="city">{{ Client.city }}</span>
                </div>
                <div class="list-item" v-if="Clients.length == 0">
                    <span class="empty">Geen resultaten</span>
                </div>
            </div>
        </div>
        <div class="filters">
            <span class="title">Filter</span>

            <span class="reset" @click="Reset" v-if="Search.String || Search.Postcode || Search.Email || Search.Phone">Reset filter</span>

            <div class="group">
                <span class="subtitle">Zoeken</span>
                <input type="text" class="search" v-model="Search.String" placeholder="Zoek op trefwoord..." @change="GetClients">
            </div>

            <div class="group">
                <span class="subtitle">Postcode</span>
                <input type="text" class="search" v-model="Search.Postcode" placeholder="Zoek een postcode..." @change="GetClients">
            </div>

            <div class="group">
                <span class="subtitle">E-mailadres</span>
                <input type="text" class="search" v-model="Search.Email" placeholder="Zoek een e-mailadres..." @change="GetClients">
            </div>

            <div class="group">
                <span class="subtitle">Telefoonnummer</span>
                <input type="text" class="search" v-model="Search.Phone" placeholder="Zoek een telefoonnummer..." @change="GetClients">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ClientOverview',

    data () {
        return {
            Clients: [],
            Search: {
                String: '',
                Postcode: '',
                Email: '',
                Phone: ''
            }
        }
    },

    mounted () {
        this.GetClients()
    },

    methods: {
        GetClients () {
            this.$http.post('https://api.wheels.nl/customers', {
                Search: this.Search.String,
                Postcode: this.Search.Postcode,
                Email: this.Search.Email,
                Phone: this.Search.Phone
            }).then((response) => {
                if(response.data.clients) { this.Clients = response.data.clients } else { this.Clients = [] }
            })
        },

        Reset () {
            this.Search.String = ''
            this.Search.Postcode = ''
            this.Search.Email = ''
            this.Search.Phone = ''

            this.GetClients()
        }
    }
}
</script>

<style scoped>
.clients {
    display: flex;
    gap: 0 50px;
    width: 100%;
    height: 100%;
    padding: 0 50px;
    background: #eeeeee;
    border: solid 1px #d7d7d7;
    border-radius: 10px 0 0 0;
    overflow: hidden;
}

.clients .list {
    position: relative;
    width: calc(100% - 350px);
    padding: 49px 0 50px 0;
    overflow: scroll;
}

.clients .list::-webkit-scrollbar {
    width: 0px;
}

.clients .list .title {
    display: block;
    width: 100%;
    font-size: 30px;
    font-weight: 300;
    margin: 0 0 25px 0;
}

.clients .list .clients-list {
    border: solid 1px #d7d7d7;
    border-radius: 5px;
    overflow: hidden;
}

.clients .list .clients-list .list-item {
    display: flex;
    align-items: center;
    height: 50px;
    background: #fff;
    border-bottom: solid 1px #d7d7d7;
}

.clients .list .clients-list .list-item:first-of-type {
    border-radius: 5px 5px 0 0;
}

.clients .list .clients-list .list-item:last-of-type {
    border-radius: 0 0 5px 5px;
}

.clients .list .clients-list .list-item .type {
    width: 100px;
    padding: 0 25px;
}

.clients .list .clients-list .list-item .type .pill {
    display: flex;
    width: fit-content;
    height: 20px;
    padding: 0 5px;
    font-size: 12px;
    font-weight: 500;
    background: #eeeeee;
    border-radius: 5px;
}

.clients .list .clients-list .list-item .type .pill.person {
    color: #fff;
    background: #00cb7d;
}

.clients .list .clients-list .list-item .type .pill.business {
    color: #fff;
    background: #cd5c5c;
}

.clients .list .clients-list .list-item .type .pill.other {
    color: #fff;
    background: #ffcc8d;
}

.clients .list .clients-list .list-item .name {
    width: calc(50% - 50px);
    font-weight: 600;
    text-decoration: none;
}

.clients .list .clients-list .list-item .city {
    width: calc(50% - 50px);
}

.clients .list .clients-list .list-item .empty {
    padding: 0 25px;
}

.clients .filters {
    position: relative;
    width: 350px;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 25px 0;
    padding: 50px 1px;
    transition: .5s;
    overflow-y: auto;
}

.clients .filters .title {
    display: block;
    width: 100%;
    font-size: 30px;
    font-weight: 300;
}

.clients .filters .group {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 15px;
    background: #ffffff;
    outline: solid 1px transparent;
    border: solid 1px #d7d7d7;
    border-radius: 5px;
}

.clients .filters .group .subtitle {
    font-weight: 500;
}

.clients .filters .group .search {
    display: block;
    width: 100%;
    height: 50px;
    padding: 15px;
    background: #eeeeee;
    border: 0;
    border-radius: 5px;
    outline: 0;
}

.clients .filters .reset, .clients .filters .create {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    background: #0071d7;
    color: #fff;
    font-weight: 600;
    outline: solid 1px transparent;
    border: solid 1px #d7d7d7;
    border-radius: 5px;
    cursor: pointer;
}
</style>