<template>
    <div class="website">
        <div class="content" :class="{ blur: Loader }">
            <span v-if="Page.Locked == 1" class="title">{{ Page.Title }}</span>
            <input v-else class="title" type="text" v-model="Page.Title">
            <div class="menu">
                <span class="item" :class="{ active: View == 'Content' }" @click="View = 'Content'">Inhoud</span>
            </div>
            <div class="block" v-if="View == 'Content' && (Page.Template == 'Page' || Page.Template == 'Blog')">
                <div class="group" v-for="(Element, i) in Page.Elements" :key="i">
                    <div class="shortcuts">
                        <svg :class="{ disabled: i == 0 }" @click="OrderContent(i, i - 1)" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g> <path fill-rule="evenodd" clip-rule="evenodd" d="M12 7C12.2652 7 12.5196 7.10536 12.7071 7.29289L19.7071 14.2929C20.0976 14.6834 20.0976 15.3166 19.7071 15.7071C19.3166 16.0976 18.6834 16.0976 18.2929 15.7071L12 9.41421L5.70711 15.7071C5.31658 16.0976 4.68342 16.0976 4.29289 15.7071C3.90237 15.3166 3.90237 14.6834 4.29289 14.2929L11.2929 7.29289C11.4804 7.10536 11.7348 7 12 7Z" fill="#000000"></path> </g></svg>
                        <svg :class="{ disabled: i == Page.Elements.length - 1 }" @click="OrderContent(i, i + 1)" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g> <path fill-rule="evenodd" clip-rule="evenodd" d="M4.29289 8.29289C4.68342 7.90237 5.31658 7.90237 5.70711 8.29289L12 14.5858L18.2929 8.29289C18.6834 7.90237 19.3166 7.90237 19.7071 8.29289C20.0976 8.68342 20.0976 9.31658 19.7071 9.70711L12.7071 16.7071C12.3166 17.0976 11.6834 17.0976 11.2929 16.7071L4.29289 9.70711C3.90237 9.31658 3.90237 8.68342 4.29289 8.29289Z" fill="#000000"></path> </g></svg>
                        <svg viewBox="0 0 24 24" @click="RemoveContent(Element)" fill="none" xmlns="http://www.w3.org/2000/svg"><g> <path fill-rule="evenodd" clip-rule="evenodd" d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V6H17H19C19.5523 6 20 6.44772 20 7C20 7.55228 19.5523 8 19 8H18V18C18 19.6569 16.6569 21 15 21H9C7.34315 21 6 19.6569 6 18V8H5C4.44772 8 4 7.55228 4 7C4 6.44772 4.44772 6 5 6H7H9V5ZM10 8H8V18C8 18.5523 8.44772 19 9 19H15C15.5523 19 16 18.5523 16 18V8H14H10ZM13 6H11V5H13V6ZM10 9C10.5523 9 11 9.44772 11 10V17C11 17.5523 10.5523 18 10 18C9.44772 18 9 17.5523 9 17V10C9 9.44772 9.44772 9 10 9ZM14 9C14.5523 9 15 9.44772 15 10V17C15 17.5523 14.5523 18 14 18C13.4477 18 13 17.5523 13 17V10C13 9.44772 13.4477 9 14 9Z" fill="#000000"></path> </g></svg>
                    </div>

                    <div class="element" v-if="Element.Component == 'Hero'">
                        <span class="subtitle">Hero afbeelding</span>
                        <div class="media" :style="{ background: '#fff url(' + Element.ImageURL + ')' }" @click="this.$root.OpenMedia(Element, 'Image')">
                            <span class="select">Selecteer een afbeelding</span>
                        </div>
                    </div>

                    <div class="element" v-if="Element.Component == 'Image'">
                        <span class="subtitle">Afbeelding</span>
                        <input type="text" v-model="Element.Alt" placeholder="Alt tekst">
                        <div class="media" :style="{ background: '#fff url(' + Element.ImageURL + ')' }" @click="this.$root.OpenMedia(Element, 'Image')">
                            <span class="select">Selecteer een afbeelding</span>
                        </div>
                    </div>

                    <div class="element" v-if="Element.Component == 'SlideShow'">
                        <span class="subtitle">Diavoorstelling</span>
                        <div class="slide" v-for="(Slide, i) in Element.Slides" :key="i">
                            <div class="item">
                                <span class="subtitle">Web</span>
                                <div class="media" :style="{ background: '#fff url(' + Slide.ImageURL + ')' }" @click="this.$root.OpenMedia(Slide, 'SlideWeb')">
                                    <span class="select">Selecteer een afbeelding</span>
                                </div>
                            </div>
                            <div class="item">
                                <span class="subtitle">Mobile</span>
                                <div class="media" :style="{ background: '#fff url(' + Slide.MobileURL + ')' }" @click="this.$root.OpenMedia(Slide, 'SlideMobile')">
                                    <span class="select">Selecteer een afbeelding</span>
                                </div>
                            </div>
                            <div class="link">
                                <span class="subtitle">Link</span>
                                <input type="text" v-model="Slide.Link">
                            </div>
                            <svg class="remove" viewBox="0 0 24 24" @click="RemoveSlide(Element.Identifier, Slide.Identifier)" fill="none" xmlns="http://www.w3.org/2000/svg"><g> <path fill-rule="evenodd" clip-rule="evenodd" d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V6H17H19C19.5523 6 20 6.44772 20 7C20 7.55228 19.5523 8 19 8H18V18C18 19.6569 16.6569 21 15 21H9C7.34315 21 6 19.6569 6 18V8H5C4.44772 8 4 7.55228 4 7C4 6.44772 4.44772 6 5 6H7H9V5ZM10 8H8V18C8 18.5523 8.44772 19 9 19H15C15.5523 19 16 18.5523 16 18V8H14H10ZM13 6H11V5H13V6ZM10 9C10.5523 9 11 9.44772 11 10V17C11 17.5523 10.5523 18 10 18C9.44772 18 9 17.5523 9 17V10C9 9.44772 9.44772 9 10 9ZM14 9C14.5523 9 15 9.44772 15 10V17C15 17.5523 14.5523 18 14 18C13.4477 18 13 17.5523 13 17V10C13 9.44772 13.4477 9 14 9Z" fill="#000000"></path> </g></svg>
                        </div>
                        <div class="slide">
                            <div class="link">
                                <span class="subtitle">Achtergrondkleur</span>
                                <input type="text" v-model="Element.Background" placeholder="#0e0e0e">
                            </div>
                        </div>
                        <div class="add" @click="AddSlide(Element.Identifier)">
                            <span class="addSlide">
                                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="#333333"><g><g><g><g><line fill="none" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" x1="12" x2="12" y1="19" y2="5"></line><line fill="none" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" x1="5" x2="19" y1="12" y2="12"></line></g></g></g></g></svg>
                            </span>
                        </div>
                    </div>

                    <div class="element" v-if="Element.Component == 'Title'">
                        <span class="subtitle">Titel</span>
                        <input type="text" v-model="Element.Title" placeholder="H1">
                        <textarea v-model="Element.Subtitle"></textarea>
                    </div>

                    <div class="element" v-if="Element.Component == 'Text'">
                        <span class="subtitle">Tekst</span>
                        <vue-file-toolbar-menu :content="DescriptionMenu" />
                        <div class="description" contenteditable="true" v-html="Element.Text" @focusout="SetContentEditable(Element, $event.target.innerHTML)"></div>
                    </div>

                    <div class="element" v-if="Element.Component == 'FourButtons'">
                        <span class="subtitle">Vier knoppen</span>
                        <div class="sub-group" v-for="(Slide, i) in Element.Slides" :key="i">
                            <span>{{ Slide.Name }}</span>
                            <div v-if="i < 2" class="media" :style="{ background: '#fff url(' + Slide.ImageURL + ')' }" @click="this.$root.OpenMedia(Slide, 'SlideWeb')">
                                <span class="select">Selecteer een afbeelding</span>
                            </div>
                            <input type="text" v-model="Slide.Text" placeholder="Titel">
                            <input type="text" v-if="i > 1" v-model="Slide.SubText" placeholder="Subtitel">
                            <input type="text" v-model="Slide.Link" placeholder="Link">
                        </div>
                    </div>

                    <div class="element" v-if="Element.Component == 'ThreeButtons'">
                        <span class="subtitle">Drie knoppen</span>
                        <div class="sub-group" v-for="(Slide, i) in Element.Slides" :key="i">
                            <span>{{ Slide.Name }}</span>
                            <div v-if="i < 3" class="media" :style="{ background: '#fff url(' + Slide.ImageURL + ')' }" @click="this.$root.OpenMedia(Slide, 'SlideWeb')">
                                <span class="select">Selecteer een afbeelding</span>
                            </div>
                            <input type="text" v-model="Slide.Text" placeholder="Titel">
                            <input type="text" v-model="Slide.Link" placeholder="Link">
                        </div>
                    </div>

                    <div class="element" v-if="Element.Component == 'USP'">
                        <span class="subtitle">Unique Selling Points</span>
                        <div class="sub-group" v-for="(Slide, i) in Element.Slides" :key="i">
                            <input type="text" v-model="Slide.Icon" placeholder="SVG <svg></svg>">
                            <input type="text" v-model="Slide.Title" placeholder="Titel">
                            <input type="text" v-model="Slide.Text" placeholder="Tekst">
                        </div>
                    </div>

                    <div class="element" v-if="Element.Component == 'USPPicture'">
                        <span class="subtitle">Unique Selling Points (incl. afbeelding)</span>
                        <div class="media" :style="{ background: '#fff url(' + Element.ImageURL + ')' }" @click="this.$root.OpenMedia(Element, 'Element')">
                            <span class="select">Selecteer een afbeelding</span>
                        </div>
                        <div class="sub-group" v-for="(Slide, i) in Element.Slides" :key="i">
                            <input type="text" v-model="Slide.Icon" placeholder="Icon <svg></svg>">
                            <input type="text" v-model="Slide.Text" placeholder="Text">
                            <input type="text" v-model="Slide.Link" placeholder="Link">
                        </div>
                    </div>

                    <div class="element" v-if="Element.Component == 'Review'">
                        <span class="subtitle">Reviews</span>
                        <div class="sub-group" v-for="(Slide, i) in Element.Slides" :key="i">
                            <div class="media" v-if="i == 0 || i == 1" :style="{ background: '#fff url(' + Slide.ImageURL + ')' }" @click="this.$root.OpenMedia(Slide, 'SlideWeb')">
                                <span class="select">Selecteer een afbeelding</span>
                            </div>
                            <input type="text" v-if="i == 0" v-model="Slide.Title" placeholder="Titel">
                            <input type="text" v-if="i == 0" v-model="Slide.Text" placeholder="Tekst">
                            <input type="text" v-if="i == 0" v-model="Slide.ButtonText" placeholder="Tekst van knop">
                            <input type="text" v-if="i == 0" v-model="Slide.ButtonLink" placeholder="Link van knop">
                            <input type="text" v-if="i == 2" v-model="Slide.Number" placeholder="Cijfer van de reviews">
                            <input type="text" v-if="i == 2" v-model="Slide.Text" placeholder="Tekst van de reviews">
                            <input type="text" v-if="i == 2" v-model="Slide.Link" placeholder="Link van de reviews">
                            <span v-if="i == 2" class="subtext">Google</span>
                            <select v-if="i == 2" v-model="Element.Google">
                                <option :value="true">Via Google Review</option>
                                <option :value="false">Niet via Google Review</option>
                            </select>
                        </div>
                    </div>

                    <div class="element" v-if="Element.Component == 'ContactMethods'">
                        <span class="subtitle">Contactmethodes</span>
                        <div class="sub-group">
                            <span class="subtitle">E-mail</span>
                            <select v-model="Element.Email.Active">
                                <option :value="true">Actief</option>
                                <option :value="false">Niet actief</option>
                            </select>
                            <vue-file-toolbar-menu :content="DescriptionMenu" />
                            <div class="description" contenteditable="true" v-html="Element.Email.Text" @focusout="SetContentEditable(Element.Email, $event.target.innerHTML)"></div>
                        </div>
                        <div class="sub-group">
                            <span class="subtitle">Telefoon</span>
                            <select v-model="Element.Phone.Active">
                                <option :value="true">Actief</option>
                                <option :value="false">Niet actief</option>
                            </select>
                            <vue-file-toolbar-menu :content="DescriptionMenu" />
                            <div class="description" contenteditable="true" v-html="Element.Phone.Text" @focusout="SetContentEditable(Element.Phone, $event.target.innerHTML)"></div>
                        </div>
                        <div class="sub-group">
                            <span class="subtitle">Chat</span>
                            <select v-model="Element.Chat.Active">
                                <option :value="true">Actief</option>
                                <option :value="false">Niet actief</option>
                            </select>
                            <vue-file-toolbar-menu :content="DescriptionMenu" />
                            <div class="description" contenteditable="true" v-html="Element.Chat.Text" @focusout="SetContentEditable(Element.Chat, $event.target.innerHTML)"></div>
                        </div>
                        <div class="sub-group">
                            <span class="subtitle">WhatsApp</span>
                            <select v-model="Element.WhatsApp.Active">
                                <option :value="true">Actief</option>
                                <option :value="false">Niet actief</option>
                            </select>
                            <vue-file-toolbar-menu :content="DescriptionMenu" />
                            <div class="description" contenteditable="true" v-html="Element.WhatsApp.Text" @focusout="SetContentEditable(Element.WhatsApp, $event.target.innerHTML)"></div>
                        </div>
                    </div>

                    <div class="element" v-if="Element.Component == 'Button'">
                        <span class="subtitle">Knop</span>

                        <span class="subtext">Tekst</span>
                        <input type="text" v-model="Element.Text">

                        <span class="subtext">Link</span>
                        <input type="text" v-model="Element.Link">

                        <span class="subtext">Extern openen</span>
                        <select v-model="Element.External">
                            <option :value="true">Ja</option>
                            <option :value="false">Nee</option>
                        </select>
                    </div>

                    <div class="element" v-if="Element.Component == 'Stock'">
                        <span class="subtitle">Voorraad</span>
                        
                        <div class="article" v-for="(Article, i) in Element.EAN" :key="i">
                            <span class="item">{{ Article }}</span>
                            <svg class="removeStock" viewBox="0 0 24 24" @click="RemoveStock(Element, Article)" fill="none" xmlns="http://www.w3.org/2000/svg"><g> <path fill-rule="evenodd" clip-rule="evenodd" d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V6H17H19C19.5523 6 20 6.44772 20 7C20 7.55228 19.5523 8 19 8H18V18C18 19.6569 16.6569 21 15 21H9C7.34315 21 6 19.6569 6 18V8H5C4.44772 8 4 7.55228 4 7C4 6.44772 4.44772 6 5 6H7H9V5ZM10 8H8V18C8 18.5523 8.44772 19 9 19H15C15.5523 19 16 18.5523 16 18V8H14H10ZM13 6H11V5H13V6ZM10 9C10.5523 9 11 9.44772 11 10V17C11 17.5523 10.5523 18 10 18C9.44772 18 9 17.5523 9 17V10C9 9.44772 9.44772 9 10 9ZM14 9C14.5523 9 15 9.44772 15 10V17C15 17.5523 14.5523 18 14 18C13.4477 18 13 17.5523 13 17V10C13 9.44772 13.4477 9 14 9Z" fill="#ffffff"></path> </g></svg>
                        </div>

                        <div class="article">
                            <input type="number" v-model="AddStockInput" placeholder="Barcode">
                            <span class="addStock" :class="{ disabled: !AddStockInput }" @click="AddStock(Element)">Toevoegen</span>
                        </div>
                    </div>

                    <div class="element" v-if="Element.Component == 'Spacer'">
                        <span class="subtitle">Vrije ruimte</span>
                        <select v-model="Element.Line">
                            <option value="false">Zonder lijn</option>
                            <option value="true">Met lijn</option>
                        </select>
                    </div>

                    <div class="element" v-if="Element.Component == 'BlogRow'">
                        <span class="subtitle">Nieuwsartikelen</span>
                        <select v-model="Element.Limit">
                            <option value="1">1 Artikel</option>
                            <option value="2">2 Artikel</option>
                            <option value="3">3 Artikel</option>
                            <option value="4">4 Artikel</option>
                            <option value="5">5 Artikel</option>
                        </select>
                    </div>

                    <div class="element" v-if="Element.Component == 'StockRow'">
                        <span class="subtitle">Laatste voorraad</span>
                    </div>

                    <div class="styling">
                        <span class="subtitle">Styling</span>
                        <svg class="toggle" v-if="!Element.Styling" @click="Element.Styling = true" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g stroke-width="0"></g><g stroke-linecap="round" stroke-linejoin="round"></g><g><path d="M4 12H20M12 4V20" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></g></svg>
                        <svg class="toggle" viewBox="0 0 24 24" v-else @click="Element.Styling = false" fill="none" xmlns="http://www.w3.org/2000/svg"><g stroke-width="0"></g><g stroke-linecap="round" stroke-linejoin="round"></g><g><path d="M6 12L18 12" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></g></svg>
                        <div class="fields" v-if="Element.Styling">
                            <span class="subtitle">Margin</span>
                            <input type="text" v-model="Element.MarginTop" placeholder="Margin Top">
                            <input type="text" v-model="Element.MarginRight" placeholder="Margin Right">
                            <input type="text" v-model="Element.MarginBottom" placeholder="Margin Bottom">
                            <input type="text" v-model="Element.MarginLeft" placeholder="Margin Left">
                        </div>
                        <div class="fields" v-if="Element.Styling">
                            <span class="subtitle">Padding</span>
                            <input type="text" v-model="Element.PaddingTop" placeholder="Padding Top">
                            <input type="text" v-model="Element.PaddingRight" placeholder="Padding Right">
                            <input type="text" v-model="Element.PaddingBottom" placeholder="Padding Bottom">
                            <input type="text" v-model="Element.PaddingLeft" placeholder="Padding Left">
                        </div>
                    </div>

                    <div class="element">
                        <div class="add page" @click="this.$root.OpenPage(i + 1)">
                            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="#333333"><g><g><g><g><line fill="none" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" x1="12" x2="12" y1="19" y2="5"></line><line fill="none" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" x1="5" x2="19" y1="12" y2="12"></line></g></g></g></g></svg>
                        </div>
                    </div>
                </div>

                <div class="empty" v-if="Page && !Page.Elements" @click="this.$root.OpenPage(i)">
                    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="#333333"><g><g><g><g><line fill="none" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" x1="12" x2="12" y1="19" y2="5"></line><line fill="none" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" x1="5" x2="19" y1="12" y2="12"></line></g></g></g></g></svg>
                </div>
            </div>
            <div class="block" v-if="View == 'Content' && (Page.Template == 'Archive')">
                <div class="group" v-for="(Element, i) in Page.Elements" :key="i">
                    <div class="element" v-if="Element.Component == 'Image'">
                        <span class="subtitle">Afbeelding</span>
                        <input type="text" v-model="Element.Alt" placeholder="Alt tekst">
                        <div class="media" :style="{ background: '#fff url(' + Element.ImageURL + ')' }" @click.self="this.$root.OpenMedia(Element, 'Image')">
                            <svg class="delete" v-if="Element.Active" viewBox="0 0 24 24" @click="Element.Active = '', Element.ImageURL = '', Element.Alt = ''" fill="none" xmlns="http://www.w3.org/2000/svg"><g> <path fill-rule="evenodd" clip-rule="evenodd" d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V6H17H19C19.5523 6 20 6.44772 20 7C20 7.55228 19.5523 8 19 8H18V18C18 19.6569 16.6569 21 15 21H9C7.34315 21 6 19.6569 6 18V8H5C4.44772 8 4 7.55228 4 7C4 6.44772 4.44772 6 5 6H7H9V5ZM10 8H8V18C8 18.5523 8.44772 19 9 19H15C15.5523 19 16 18.5523 16 18V8H14H10ZM13 6H11V5H13V6ZM10 9C10.5523 9 11 9.44772 11 10V17C11 17.5523 10.5523 18 10 18C9.44772 18 9 17.5523 9 17V10C9 9.44772 9.44772 9 10 9ZM14 9C14.5523 9 15 9.44772 15 10V17C15 17.5523 14.5523 18 14 18C13.4477 18 13 17.5523 13 17V10C13 9.44772 13.4477 9 14 9Z" fill="#000000"></path> </g></svg>
                            <span class="select" v-if="Element.Active">Selecteer een andere afbeelding</span>
                            <span class="select" v-else>Selecteer een afbeelding</span>
                        </div>
                    </div>

                    <div class="element" v-if="Element.Component == 'Title'">
                        <span class="subtitle">Titel</span>
                        <input type="text" v-model="Element.Title" placeholder="H1">
                        <vue-file-toolbar-menu :content="DescriptionMenu" />
                        <svg class="gpt" v-if="Element.Title" @click="GPTDescription(Element)" :class="{ disabled: Element.Stream }" fill="none" xmlns="http://www.w3.org/2000/svg" stroke-width="1.5" viewBox="-0.17090198558635983 0.482230148717937 41.14235318283891 40.0339509076386"><path d="M37.532 16.87a9.963 9.963 0 0 0-.856-8.184 10.078 10.078 0 0 0-10.855-4.835A9.964 9.964 0 0 0 18.306.5a10.079 10.079 0 0 0-9.614 6.977 9.967 9.967 0 0 0-6.664 4.834 10.08 10.08 0 0 0 1.24 11.817 9.965 9.965 0 0 0 .856 8.185 10.079 10.079 0 0 0 10.855 4.835 9.965 9.965 0 0 0 7.516 3.35 10.078 10.078 0 0 0 9.617-6.981 9.967 9.967 0 0 0 6.663-4.834 10.079 10.079 0 0 0-1.243-11.813zM22.498 37.886a7.474 7.474 0 0 1-4.799-1.735c.061-.033.168-.091.237-.134l7.964-4.6a1.294 1.294 0 0 0 .655-1.134V19.054l3.366 1.944a.12.12 0 0 1 .066.092v9.299a7.505 7.505 0 0 1-7.49 7.496zM6.392 31.006a7.471 7.471 0 0 1-.894-5.023c.06.036.162.099.237.141l7.964 4.6a1.297 1.297 0 0 0 1.308 0l9.724-5.614v3.888a.12.12 0 0 1-.048.103l-8.051 4.649a7.504 7.504 0 0 1-10.24-2.744zM4.297 13.62A7.469 7.469 0 0 1 8.2 10.333c0 .068-.004.19-.004.274v9.201a1.294 1.294 0 0 0 .654 1.132l9.723 5.614-3.366 1.944a.12.12 0 0 1-.114.01L7.04 23.856a7.504 7.504 0 0 1-2.743-10.237zm27.658 6.437l-9.724-5.615 3.367-1.943a.121.121 0 0 1 .113-.01l8.052 4.648a7.498 7.498 0 0 1-1.158 13.528v-9.476a1.293 1.293 0 0 0-.65-1.132zm3.35-5.043c-.059-.037-.162-.099-.236-.141l-7.965-4.6a1.298 1.298 0 0 0-1.308 0l-9.723 5.614v-3.888a.12.12 0 0 1 .048-.103l8.05-4.645a7.497 7.497 0 0 1 11.135 7.763zm-21.063 6.929l-3.367-1.944a.12.12 0 0 1-.065-.092v-9.299a7.497 7.497 0 0 1 12.293-5.756 6.94 6.94 0 0 0-.236.134l-7.965 4.6a1.294 1.294 0 0 0-.654 1.132l-.006 11.225zm1.829-3.943l4.33-2.501 4.332 2.5v5l-4.331 2.5-4.331-2.5V18z" fill="currentColor"/></svg>
                        <div class="description" contenteditable="true" v-html="Element.Text" @focusout="SetContentEditable(Element, $event.target.innerHTML)" :class="{ stream: Element.Stream }"></div>
                    </div>
                </div>
            </div>
            <div class="block" v-if="View == 'Content' && (Page.Template == 'Archive')">
                <div class="group">
                    <div class="element">
                        <span class="subtitle">Filter</span>
                    </div>
                    <div class="query-header">
                        <span class="column">Kolom</span>
                        <span class="operator"></span>
                        <span class="value">Waarde</span>
                        <span class="delete"></span>
                    </div>
                    <div class="query-row" v-for="(Item, i) in Page.Query" :key="i">
                        <select v-model="Item.Column" class="column">
                            <option v-for="(Column, i) in Columns" :key="i" :value="Column.Value">{{ Column.Name }}</option>
                        </select>
                        <span v-if="Columns.find(item => item.Value == Item.Column) && Columns.find(item => item.Value == Item.Column).Options" class="empty">Kies een optie</span>
                        <select v-else v-model="Item.Operator" class="operator">
                            <option v-for="(Operator, i) in Operators" :key="i" :value="Operator.Value">{{ Operator.Label }}</option>
                        </select>
                        <select v-if="Columns.find(item => item.Value == Item.Column) && Columns.find(item => item.Value == Item.Column).Options" v-model="Item.Value" class="value">
                            <option v-for="(Option, i) in Columns.find(item => item.Value == Item.Column).Options" :key="i" :value="Option.Value">{{ Option.Name }}</option>
                        </select>
                        <input v-else v-model="Item.Value" type="text" class="value">
                        <svg class="delete" viewBox="0 0 24 24" @click="RemoveQueryRow(Item)" fill="none" xmlns="http://www.w3.org/2000/svg"><g> <path fill-rule="evenodd" clip-rule="evenodd" d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V6H17H19C19.5523 6 20 6.44772 20 7C20 7.55228 19.5523 8 19 8H18V18C18 19.6569 16.6569 21 15 21H9C7.34315 21 6 19.6569 6 18V8H5C4.44772 8 4 7.55228 4 7C4 6.44772 4.44772 6 5 6H7H9V5ZM10 8H8V18C8 18.5523 8.44772 19 9 19H15C15.5523 19 16 18.5523 16 18V8H14H10ZM13 6H11V5H13V6ZM10 9C10.5523 9 11 9.44772 11 10V17C11 17.5523 10.5523 18 10 18C9.44772 18 9 17.5523 9 17V10C9 9.44772 9.44772 9 10 9ZM14 9C14.5523 9 15 9.44772 15 10V17C15 17.5523 14.5523 18 14 18C13.4477 18 13 17.5523 13 17V10C13 9.44772 13.4477 9 14 9Z" fill="#939393"></path> </g></svg>
                    </div>
                    <div class="element">
                        <div class="add" @click="AddQueryRow()">
                            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="#333333"><g><g><g><g><line fill="none" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" x1="12" x2="12" y1="19" y2="5"></line><line fill="none" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" x1="5" x2="19" y1="12" y2="12"></line></g></g></g></g></svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="sidebar" :class="{ blur: Loader }">
            <div class="block">
                <div class="group" v-if="Page.Locked == 0">
                    <div class="element">
                        <div class="status" :class="Page.Status"></div>
                        <span class="subtitle">Status</span>
                        <v-select v-model="Page.Status" class="select" :options="Statusses" label="Label" :clearable="false" :reduce="template => template.Value" placeholder="Kies een optie..."></v-select>
                    </div>
                </div>
                <div class="group">
                    <div class="element">
                        <span class="subtitle">Slug</span>
                        <input v-if="Page.Locked == 0" v-model="Page.Slug" type="text">
                        <span v-else class="slug">{{ Page.Slug }}</span>
                        <a :href="'https://' + Client.Host + '/' + Page.Slug + '/' + Page.ConceptKey" target="_blank" v-if="Page.Slug && Page.Status == 'C'">
                            <svg class="external" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><g><path id="Vector" d="M10.0002 5H8.2002C7.08009 5 6.51962 5 6.0918 5.21799C5.71547 5.40973 5.40973 5.71547 5.21799 6.0918C5 6.51962 5 7.08009 5 8.2002V15.8002C5 16.9203 5 17.4801 5.21799 17.9079C5.40973 18.2842 5.71547 18.5905 6.0918 18.7822C6.5192 19 7.07899 19 8.19691 19H15.8031C16.921 19 17.48 19 17.9074 18.7822C18.2837 18.5905 18.5905 18.2839 18.7822 17.9076C19 17.4802 19 16.921 19 15.8031V14M20 9V4M20 4H15M20 4L13 11" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></g></g></svg>
                        </a>
                        <a :href="'https://' + Client.Host + '/' + Page.Slug" target="_blank" v-if="Page.Slug && Page.Status == 'O'">
                            <svg class="external" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><g><path id="Vector" d="M10.0002 5H8.2002C7.08009 5 6.51962 5 6.0918 5.21799C5.71547 5.40973 5.40973 5.71547 5.21799 6.0918C5 6.51962 5 7.08009 5 8.2002V15.8002C5 16.9203 5 17.4801 5.21799 17.9079C5.40973 18.2842 5.71547 18.5905 6.0918 18.7822C6.5192 19 7.07899 19 8.19691 19H15.8031C16.921 19 17.48 19 17.9074 18.7822C18.2837 18.5905 18.5905 18.2839 18.7822 17.9076C19 17.4802 19 16.921 19 15.8031V14M20 9V4M20 4H15M20 4L13 11" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></g></g></svg>
                        </a>
                    </div>
                </div>
                <div class="group" v-if="Page.Locked == 0">
                    <div class="element">
                        <span class="subtitle">Template</span>
                        <v-select v-model="Page.Template" class="select" :options="Templates" label="Label" :clearable="false" :reduce="template => template.Value" placeholder="Kies een optie..."></v-select>
                    </div>
                </div>
                <div class="group" v-if="Page.Template == 'Blog'">
                    <div class="element">
                        <span class="subtitle">Auteur</span>
                        <v-select v-model="Page.Author" class="select" :options="Authors" label="Name" :clearable="false" :reduce="template => template.Identifier" placeholder="Kies een auteur..."></v-select>
                    </div>
                </div>
                <div class="group" v-if="Page.Template == 'Page' && Page.Locked == 0">
                    <div class="element">
                        <span class="subtitle">Kruimelpad</span>
                        <v-select v-model="Page.BreadCrumbs" class="select" :options="BreadCrumbs" label="Label" :clearable="false" :reduce="template => template.Value.toString()" placeholder="Kies een optie..."></v-select>
                    </div>
                </div>
                <div class="group">
                    <div class="element">
                        <span class="subtitle">Uitgelichte foto</span>
                        <div class="media" :style="{ background: '#fff url(https://api.wheels.nl/vault/' + Client.Identifier + '/' + Page.Thumbnail + '.png)' }" @click.self="this.$root.OpenMedia(Page, 'Thumbnail')">
                            <svg class="delete" v-if="Page.Thumbnail" viewBox="0 0 24 24" @click="Page.Thumbnail = '', Page.ThumbnailSrc = ''" fill="none" xmlns="http://www.w3.org/2000/svg"><g> <path fill-rule="evenodd" clip-rule="evenodd" d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V6H17H19C19.5523 6 20 6.44772 20 7C20 7.55228 19.5523 8 19 8H18V18C18 19.6569 16.6569 21 15 21H9C7.34315 21 6 19.6569 6 18V8H5C4.44772 8 4 7.55228 4 7C4 6.44772 4.44772 6 5 6H7H9V5ZM10 8H8V18C8 18.5523 8.44772 19 9 19H15C15.5523 19 16 18.5523 16 18V8H14H10ZM13 6H11V5H13V6ZM10 9C10.5523 9 11 9.44772 11 10V17C11 17.5523 10.5523 18 10 18C9.44772 18 9 17.5523 9 17V10C9 9.44772 9.44772 9 10 9ZM14 9C14.5523 9 15 9.44772 15 10V17C15 17.5523 14.5523 18 14 18C13.4477 18 13 17.5523 13 17V10C13 9.44772 13.4477 9 14 9Z" fill="#000000"></path> </g></svg>
                        </div>
                    </div>
                </div>
                <span class="save" @click="SavePage" :class="{ disabled: Save }">Opslaan</span>
            </div>
        </div>
    </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import OpenAI from 'openai';
import VueFileToolbarMenu from 'vue-file-toolbar-menu'

export default {
    name: 'WebsiteItem',

    components: {
        VueFileToolbarMenu
    },

    data () {
        return {
            Loader: true,
            View: 'Content',
            Page: [],
            Client: [],
            Statusses: [
                {
                    Label: 'Online',
                    Value: 'O'
                },
                {
                    Label: 'Concept',
                    Value: 'C'
                },
                {
                    Label: 'Offline',
                    Value: 'A'
                }
            ],
            Templates: [
                {
                    Label: 'Fietsen (Categorie)',
                    Value: 'Bikes'
                },
                {
                    Label: 'Fietsen (Marketing)',
                    Value: 'BikesArchive'
                },
                {
                    Label: 'Accessoires (Categorie)',
                    Value: 'Accessoires'
                },
                {
                    Label: 'Accessoires (Marketing)',
                    Value: 'AccessoiresMarketing'
                },
                {
                    Label: 'Pagina',
                    Value: 'Page'
                },
                {
                    Label: 'Blog',
                    Value: 'Blog'
                },
                {
                    Label: 'Archive',
                    Value: 'Archive'
                }
            ],
            BreadCrumbs: [
                {
                    Label: 'Aan',
                    Value: 1
                },
                {
                    Label: 'Uit',
                    Value: 0
                }
            ],
            Columns: [],
            Operators: [
                {
                    Label: 'is gelijk aan',
                    Value: 'IS'
                },
                {
                    Label: 'bevat',
                    Value: 'LIKE'
                },
                {
                    Label: 'is lager dan',
                    Value: '<'
                },
                {
                    Label: 'is gelijk aan of lager dan',
                    Value: '<='
                },
                {
                    Label: 'is gelijk aan of hoger dan',
                    Value: '>='
                },
                {
                    Label: 'is hoger dan',
                    Value: '>'
                }
            ],
            Authors: [],
            AddStockInput: '',
            Save: false
        }
    },

    mounted () {
        this.GetPage()
        this.GetColumns()

        var self = this
        this.$mitt.on('ImageSelected', (e) => { self.ChangeMedia(e); })
        this.$mitt.on('ElementSelected', (e) => { self.AddElement(e); })
    },

    methods: {
        GetPage () {
            this.Loader = true
            this.$http.get(`https://api.wheels.nl/pages/${this.$route.params.id}`).then((response) => {
                if(response.data.page) {
                    this.Page = response.data.page
                    this.Client = response.data.client
                    this.Authors = response.data.authors
                    this.Loader = false
                } else {
                    this.$router.push('/website/pagina')
                    this.$toast.open({
                        message: 'Pagina niet gevonden',
                        position: 'bottom-right',
                        type: 'error'
                    })
                }
            })
        },

        SavePage () {
            this.Save = true
            this.$http.patch(`https://api.wheels.nl/pages`, {
                Page: JSON.stringify(this.Page)
            }).then(() => {
                this.Save = false

                this.$toast.open({
                    message: 'Pagina opgeslagen',
                    position: 'top-right',
                    type: 'success'
                })
            })
        },

        GetColumns () {
            this.$http.get('https://api.wheels.nl/pages/columns').then((response) => {
                if(response.data.columns) {
                    this.Columns = response.data.columns
                } else {
                    this.Columns = []
                }
            })
        },

        OrderContent (e, a) {
            var tmp = this.Page.Elements[e];
            this.Page.Elements[e] = this.Page.Elements[a];
            this.Page.Elements[a] = tmp;
        },

        RemoveContent (e) {
            this.Page.Elements = this.Page.Elements.filter(item => item !== e)

            if(this.Page.Elements.length == 0) {
                this.Page.Elements = false
            }
        },

        RemoveSlide (e, a) {
            this.Page.Elements.find(item => item.Identifier == e).Slides = this.Page.Elements.find(item => item.Identifier == e).Slides.filter(item => item.Identifier !== a)
        },

        AddQueryRow () {
            if(!Array.isArray(this.Page.Query)) { this.Page.Query = [] }
            this.Page.Query.push({
                "Column": '',
                "Operator": '',
                "Value": ''
            })
        },

        RemoveQueryRow (e) {
            this.Page.Query = this.Page.Query.filter(item => item !== e)
        },

        GPTDescription (e) {
            e.Stream = true

            this.$http.get(`https://api.wheels.nl/stock/ai/token`).then((response) => {
                const openai = new OpenAI({
                    apiKey: response.data.token,
                    dangerouslyAllowBrowser: true
                })

                this.GPTStream(e, openai)
            })
        },

        async GPTStream (e, openai) {
            e.Text = ''

            const stream = await openai.chat.completions.create({
                model: 'gpt-3.5-turbo',
                messages: [{ role: 'user', content: 'Schrijf een seo tekst van één alinea voor een pagina met de titel ' + e.Title }],
                stream: true
            })

            for await (const chunk of stream) {
                e.Text = e.Text + (chunk.choices[0]?.delta?.content || '')
            }

            e.Stream = false
        },

        ChangeMediaSearch (e, a, z) {            
            for (const [key, value] of Object.entries(e)) {
                if(key == 'Active' && value == a) {
                    this.SetMedia(e, z)
                }

                if(Array.isArray(value)) {
                    this.ChangeMediaSearch(value, a, z)
                }

                if(typeof value === 'object' && value !== null) {
                    this.ChangeMediaSearch(value, a, z)
                }
            }
        },

        ChangeMedia (a) {
            if(a.Type == 'Thumbnail') {
                this.SetMedia('Thumbnail', a)
            } else {
                this.Page.Elements.forEach(e => {
                    this.ChangeMediaSearch(e, a.Focus, a)
                })
            }
        },

        SetMedia (e, a) {
            this.$http.get(`https://api.wheels.nl/pages/media/${a.Selected}`).then((response) => {
                if(a.Type == 'SlideWeb') {
                    if(response.data.Image) { e.ImageURL = response.data.Image }
                    if(response.data.Video) { e.VideoURL = response.data.Video } else { e.VideoURL = false }
                    e.Image = e.Selected,
                    e.Alt = response.data.Alt
                } else if(a.Type == 'SlideMobile') {
                    e.Mobile = e.Selected,
                    e.MobileURL = response.data.Image
                    e.Alt = response.data.Alt
                } else if (a.Type == 'Thumbnail') {
                    this.Page.Thumbnail = a.Selected
                    this.Page.ThumbnailSrc = response.data.Image
                } else {
                    e.Image = e.Selected,
                    e.ImageURL = response.data.Image
                    e.Alt = response.data.Alt
                }
            })
        },

        AddElement (e) {
            if(!this.Page.Elements) {
                this.Page.Elements = []
            }

            if(e.Selected.Array) {
                this.Page.Elements.splice(e.Index, 0, JSON.parse(e.Selected.Array))
            }
        },

        AddStock (e) {
            if(!this.Page.Elements.find(element => element == e).EAN) { this.Page.Elements.find(element => element == e).EAN = [] }
            this.Page.Elements.find(element => element == e).EAN.push(this.AddStockInput)
            this.AddStockInput = ''
        },

        AddSlide (e) {
            if(!this.Page.Elements.find(element => element.Identifier == e).Slides) {
                this.Page.Elements.find(element => element.Identifier == e).Slides = []
            }

            this.Page.Elements.find(element => element.Identifier == e).Slides.push({
                Identifier: uuidv4(),
                Image: '',
                ImageURL: '',
                Mobile: '',
                MobileURL: '',
                Alt: ''
            })
        },

        RemoveStock (e, a) {
            this.Page.Elements.find(element => element == e).EAN = this.Page.Elements.find(element => element == e).EAN.filter(article => article !== a)
        },

        SetContentEditable (e, a) {
            e.Text = a
        },

        SetLink () {
            let link = prompt("Geef een link op:", "/");
            if (link) {
                document.execCommand("CreateLink", false, link);
            }
        }
    },

    computed: {
        DescriptionMenu () {
            return [
                { icon: "format_align_left", title: "Align left", click: () => document.execCommand("justifyLeft") },
                { icon: "format_align_center", title: "Align center", click: () => document.execCommand("justifyCenter") },
                { icon: "format_align_right", title: "Align right", click: () => document.execCommand("justifyRight") },
                { icon: "format_align_justify", title: "Justify content", click: () => document.execCommand("justifyFull") },
                { is: "separator" },
                { icon: "format_bold", title: "Bold", click: () => document.execCommand("bold") },
                { icon: "format_italic", title: "Italic", click: () => document.execCommand("italic") },
                { icon: "format_underline", title: "Underline", click: () => document.execCommand("underline") },
                { icon: "format_strikethrough", title: "Strike through", click: () => document.execCommand("strikethrough") },
                { is: "separator" },
                { html: "<p>Link</p>", title: "Link", click: () => this.SetLink() },
                { is: "separator" },
                { icon: "format_list_numbered", title: "Numbered list", click: () => document.execCommand("insertOrderedList") },
                { icon: "format_list_bulleted", title: "Bulleted list", click: () => document.execCommand("insertUnorderedList") },
                { is: "separator" },
                { icon: "format_indent_increase", title: "Increase indent", click: () => document.execCommand("indent") },
                { icon: "format_indent_decrease", title: "Decrease indent", click: () => document.execCommand("outdent") },
                { is: "separator" },
                { html: "<b>H1</b>", title: "Header 1", click: () => document.execCommand('formatBlock', false, '<h1>') },
                { html: "<b>H2</b>", title: "Header 2", click: () => document.execCommand('formatBlock', false, '<h2>') },
                { html: "<b>H3</b>", title: "Header 3", click: () => document.execCommand('formatBlock', false, '<h3>') },
                { icon: "format_clear", text: "Clear", title: "Clear format", click () { document.execCommand('removeFormat'); document.execCommand('formatBlock', false, '<div>'); } }
            ]
        }
    }
}
</script>

<style scoped>
.website {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
    padding: 50px;
    background: #eeeeee;
    border: solid 1px #d7d7d7;
    border-radius: 10px 0 0 0;
    overflow: auto;
}

.website .content {
    display: flex;
    flex-direction: column;
    gap: 25px;
    width: calc(70% - 25px);
    max-width: 800px;
    margin: 0 0 0 auto;
    transition: .5s;
}

.website .content.blur {
    filter: blur(5px);
    -webkit-filter: blur(5px);
    pointer-events: none;
}

.website .content .title {
    display: flex;
    align-items: center;
    height: 50px;
    padding: 0 15px;
    background: #fff;
    font-weight: 700;
    border: solid 1px #d7d7d7;
    border-radius: 10px;
}

.website .content .menu {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 25px 0;
}

.website .content .menu .item {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    width: 100%;
    height: 50px;
    border-bottom: solid 2px #d7d7d7;
    cursor: pointer;
}

.website .content .menu .item.active {
    color: #0171d7;
    font-weight: 700;
    border-bottom: solid 2px #0171d7;
}

.website .content .menu .item .counter {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    min-width: 20px;
    padding: 0px 5px;
    font-size: 10px;
    font-weight: 700;
    background: #0171d7;
    color: #fff;
    border-radius: 5px;
}

.website .content .block {
    display: flex;
    flex-direction: column;
    gap: 35px;
    padding: 25px;
    background: #fff;
    border: solid 1px #d7d7d7;
    border-radius: 10px;
}

.website .content .block.members {
    gap: 10px;
}

.website .content .block.changed {
    outline: solid 1px #ffa500;
}

.website .content .block .group {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 25px;
    background: #eeeeee;
    border-radius: 10px;
}

.website .content .block .group .shortcuts {
    position: absolute;
    top: 25px;
    right: 25px;
    display: flex;
    gap: 10px;
}

.website .content .block .group .shortcuts svg {
    background: #fff;
    width: 25px;
    height: 25px;
    padding: 2.5px;
    border-radius: 5px;
    cursor: pointer;
}

.website .content .block .group .shortcuts svg.disabled {
    pointer-events: none;
    opacity: 0.35;
}

.website .content .block .group .element {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.website .content .block .group .element .ai {
    position: absolute;
    top: 28px;
    right: 25px;
    width: 25px;
    fill: #333;
    cursor: pointer;
}

.website .content .block .group .element .subtitle {
    font-size: 20px;
    font-weight: 500;
}

.website .content .block .group .element .subtext {
    font-size: 16px;
    font-weight: 500;
    margin: 0 0 -5px 0;
}

.website .content .block .group .element .sub-group {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 25px;
    background: #fff;
    border-radius: 10px;
}

.website .content .block .group .element .media {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
    background: #fff;
    background-position: 50% !important;
    background-size: cover !important;
    border: dashed 1px #ababab;
    border-radius: 10px;
    cursor: pointer;
}

.website .content .block .group .element .media:hover .delete, .website .content .block .group .element .media:hover .select {
    opacity: 1;
}

.website .content .block .group .element input {
    height: 50px;
    padding: 0 15px;
    background: #fff;
    border: solid 1px #d7d7d7;
    border-radius: 10px;
}

.website .content .block .group .element select {
    height: 50px;
    padding: 0 15px;
    background: #fff;
    border: solid 1px #d7d7d7;
    border-radius: 10px;
}

.website .content .block .group .element textarea {
    min-height: 200px;
    padding: 15px;
    background: #fff;
    border: solid 1px #d7d7d7;
    border-radius: 10px;
    resize: none;
}

.website .content .block .group .element textarea::-webkit-scrollbar {
    width: 5px;
}

.website .content .block .group .element textarea::-webkit-scrollbar-track {
    margin: 10px;
    background: transparent;
}

.website .content .block .group .element textarea::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

.website .content .block .group .element textarea::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.website .content .block .group .element .select {
    position: absolute;
    bottom: 15px;
    right: 15px;
    background: #fff;
    padding: 10px 25px;
    border: solid 1px #d7d7d7;
    border-radius: 10px;
    pointer-events: none;
    transition: .25s;
    opacity: 0.75;
}

.website .content .block .group .element .delete {
    position: absolute;
    bottom: 15px;
    left: 15px;
    width: 48px;
    height: 48px;
    padding: 10px;
    background: #fff;
    border: solid 1px #d7d7d7;
    border-radius: 10px;
    transition: .25s;
    opacity: 0.75;
}

.website .content .block .group .element .article {
    display: flex;
    gap: 10px;
}

.website .content .block .group .element .article input {
    width: 100%;
}

.website .content .block .group .element .article .item {
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
    padding: 0 15px;
    background: #fff;
    border: solid 1px #d7d7d7;
    border-radius: 10px;
    opacity: 0.75;
}

.website .content .block .group .element .article .addStock {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    padding: 0 15px;
    background: #0071d7;
    color: #fff;
    font-weight: 600;
    border: solid 1px #d7d7d7;
    border-radius: 10px;
    cursor: pointer;
}

.website .content .block .group .element .article .addStock.disabled {
    pointer-events: none;
    opacity: 0.5;
}

.website .content .block .group .element .article .removeStock {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    padding: 10px;
    background: #cd5c5c;
    font-weight: 600;
    border: solid 1px #d7d7d7;
    border-radius: 10px;
    cursor: pointer;
}

.website .content .block .group .element .slide {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
    margin: 0 0 15px 0;
    padding: 25px;
    background: #fff;
    border-radius: 10px;
}

.website .content .block .group .element .slide .item {
    width: calc(50% - 12.5px);
}

.website .content .block .group .element .slide .link {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.website .content .block .group .element .slide .link input {
    width: 100%;
}

.website .content .block .group .element .slide .remove {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.website .content .block .group .element .bar {
    gap: 10px;
    padding: 10px 0;
}

.website .content .block .group .element .bar:deep(.bar-button) {
    padding: 0;
    cursor: pointer;
}

.website .content .block .group .element .gpt {
    position: absolute;
    top: 25px;
    right: 25px;
    width: 25px;
    height: 25px;
    cursor: pointer;
}

.website .content .block .group .element .gpt.disabled {
    pointer-events: none;
    opacity: 0.5;
}

.website .content .block .group .element .description {
    min-height: 200px;
    padding: 15px;
    background: #fff;
    border: solid 1px #d7d7d7;
    border-radius: 10px;
    resize: none;
}

.website .content .block .group .element .description.stream {
    background: #e3e3e3;
    color: #b8b8b8;
    pointer-events: none;
}

.website .content .block .group .element .description.stream:deep(*) {
    color: #b8b8b8;
}

.website .content .block .group .element .description.changed {
    border: solid 1px #ffa500;
}

.website .content .block .group .element .description:deep(h1) {
    font-size: 24px;
    font-weight: 700;
}

.website .content .block .group .element .description:deep(h2) {
    font-size: 20px;
    font-weight: 500;
}

.website .content .block .group .element .description:deep(h3) {
    font-weight: 500;
}

.website .content .block .group .element .description:deep(b) {
    font-weight: 600;
}

.website .content .block .group .element .description:deep(i) {
    font-style: italic;
}

.website .content .block .group .element .description:deep(ol), .website .content .block .description:deep(ul) {
    padding: 0 0 0 25px;
}

.website .content .block .group .element .group-title {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    font-weight: 600;
    border-bottom: solid 1px #d3d3d3;
    cursor: pointer;
}

.website .content .block .group .element .group-title svg {
    width: 25px;
    height: 25px;
}

.website .content .block .group .element .group-block {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 10px 0;
}

.website .content .block .group .element .group-block .group-input {
    position: relative;
    display: flex;
    align-items: center;
}

.website .content .block .group .element .group-block .group-input span {
    font-size: 14px;
    width: 25%;
}

.website .content .block .group .element .group-block .group-input input {
    width: 75%;
    height: 40px;
    padding: 0 35px 0 10px;
    background: #fff;
    border: solid 1px #d7d7d7;
    border-radius: 10px;
    outline: 0;
}

.website .content .block .group .element .group-block .group-input input:disabled {
    background: #f3f3f3;
}

.website .content .block .group .element .group-block .group-input input.changed {
    border: solid 1px #ffa500;
}

.website .content .block .group .element .group-block .group-input .external {
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.website .content .block .group .element .group-block .group-input .tip {
    position: absolute;
    bottom: 1px;
    right: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    min-width: 35px;
    height: 38px;
    padding: 0 10px;
    background: #d7d7d7;
    border-radius: 0 10px 10px 0;
}

.website .content .block .group .element .upload {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.website .content .block .group .element .upload .item {
    display: flex;
    align-items: center;
    gap: 25px;
}

.website .content .block .group .element .upload .item .image {
    width: 75px;
    height: 75px;
    object-fit: cover;
    border: solid 1px #d7d7d7;
    border-radius: 10px;
}

.website .content .block .group .element .upload .item .image-thumbnail {
    width: 75px;
    padding: 15px;
    border: solid 1px #d7d7d7;
    border-radius: 10px;
}

.website .content .block .group .element .upload .item .information {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.website .content .block .group .element .upload .item .information .name {
    line-height: 16px;
    font-weight: 700;
}

.website .content .block .group .element .upload .item .information .type {
    font-size: 14px;
    font-weight: 300;
    line-height: 14px;
}

.website .content .block .group .element .upload .item .information .size {
    font-weight: 300;
    font-size: 14px;
    line-height: 14px;
}

.website .content .block .group .element .upload .divider {
    width: 100%;
    height: 1px;
    margin: 15px 0;
    border-bottom: dashed 1px #d7d7d7;
}

.website .content .block .group .element .add {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border: dashed 1px #ababab;
    border-radius: 10px;
    cursor: pointer;
}

.website .content .block .group .element .add.page {
    margin: 25px 0 0 0;
    background: #fff;
    border: 0;
}

.website .content .block .group .element .add svg {
    width: 35px;
    height: 35px;
}

.website .content .block .group .query-header, .website .content .block .group .query-row {
    display: flex;
    gap: 10px;
}

.website .content .block .group .query-header .column, .website .content .block .group .query-header .operator, .website .content .block .group .query-header .value {
    width: calc(100% / 3);
    font-weight: 500;
    color: #939393;
}

.content .block .group .query-header .delete {
    width: 25px;
}

.website .content .block .group .query-row .column, .website .content .block .group .query-row .operator, .website .content .block .group .query-row .value {
    width: calc(100% / 3);
    padding: 10px;
    border: solid 1px #d7d7d7;
    border-radius: 5px;
    outline: none;
}

.website .content .block .group .query-row .delete {
    width: 25px;
    cursor: pointer;
}

.website .content .block .group .query-row .empty {
    width: calc(100% / 3);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    color: #b9b9b9;
}

.website .content .block .group .styling {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding: 10px 25px;
    margin: 15px 0 0 0;
    border: solid 1px #d7d7d7;
    border-radius: 10px;
}

.website .content .block .group .styling .subtitle {
    width: 100%;
    font-size: 20px;
    font-weight: 500;
}

.website .content .block .group .styling .toggle {
    position: absolute;
    top: 13px;
    right: 25px;
    width: 25px;
    height: 25px;
    cursor: pointer;
}

.website .content .block .group .styling .fields {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    padding: 15px;
    background: #fff;
    border-radius: 10px;
}

.website .content .block .group .styling .fields input {
    width: calc(50% - 5px);
    height: 50px;
    padding: 0 15px;
    background: #fff;
    border: solid 1px #d7d7d7;
    border-radius: 10px;
}




.website .content .block .empty {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border: dashed 1px #ababab;
    border-radius: 10px;
    cursor: pointer;
}

.website .content .block .empty svg {
    width: 35px;
    height: 35px;
}





.website .sidebar {
    display: flex;
    flex-direction: column;
    gap: 25px;
    width: 30%;
    max-width: 300px;
    margin: 0 auto 0 0;
    transition: .5s;
}

.website .sidebar.blur {
    filter: blur(5px);
    -webkit-filter: blur(5px);
    pointer-events: none;
}

.website .sidebar .block {
    display: flex;
    flex-direction: column;
    gap: 35px;
    margin: 200px 0 0 0;
    padding: 25px;
    background: #fff;
    border: solid 1px #d7d7d7;
    border-radius: 10px;
    transition: .5s;
}

.website .sidebar .block .save {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    padding: 0 15px;
    background: #0171d7;
    color: #fff;
    font-weight: 700;
    border-radius: 10px;
    transition: .5s;
    cursor: pointer;
}

.website .sidebar .block .save.disabled {
    pointer-events: none;
    opacity: 0.5;
}

.website .sidebar .block .element {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 25px;
    background: #eeeeee;
    border-radius: 10px;
}

.website .sidebar .block .element .subtitle {
    font-size: 20px;
    font-weight: 500;
}

.website .sidebar .block .element .status {
    position: absolute;
    top: 35px;
    right: 25px;
    width: 15px;
    height: 15px;
    background: lightgray;
    border-radius: 50%;
}

.website .sidebar .block .element .status.O {
    background: #8fbc8f;
}

.website .sidebar .block .element .status.C {
    background: #ffa500;
}

.website .sidebar .block .element .status.A {
    background: #cd5c5c;
}

.website .sidebar .block .element .select:deep(.vs__dropdown-toggle) {
    height: 50px;
    background: #fff;
    border-radius: 10px;
}

.website .sidebar .block .element input {
    height: 50px;
    padding: 0 15px;
    background: #fff;
    border: solid 1px #d7d7d7;
    border-radius: 10px;
    outline: none;
}

.website .sidebar .block .element .slug {
    display: flex;
    align-items: center;
    height: 50px;
    padding: 0 15px;
    background: #fff;
    border: solid 1px #d7d7d7;
    border-radius: 10px;
}

.website .sidebar .block .element .external {
    position: absolute;
    bottom: 47.5px;
    right: 35px;
    width: 25px;
    height: 25px;
}

.website .sidebar .block .element .media {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 140px;
    background: #fff;
    background-position: 50% !important;
    background-size: cover !important;
    border: dashed 1px #ababab;
    border-radius: 10px;
    cursor: pointer;
}

.website .sidebar .block .element .media .delete {
    position: absolute;
    bottom: 15px;
    right: 15px;
    width: 48px;
    height: 48px;
    padding: 10px;
    background: #fff;
    border: solid 1px #d7d7d7;
    border-radius: 10px;
    transition: .25s;
    opacity: 0.75;
}

.website .sidebar .block .element .media:hover .delete {
    opacity: 1;
}
</style>